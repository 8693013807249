import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../../components/Button';
import styles from '../PageNotFound/PageNotFound.module.scss';
import Title from '../../components/Title';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.notfound}
      style={{
        minHeight: '100vh'
      }}
    >
      <div className={styles.notfound_content}>
        <Title>Something went wrong!</Title>
        <p>This is not the page you&apos;re looking for.</p>
        <Button onClick={() => navigate('/')}>
          <ArrowBackIcon />
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
