import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, Container, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getDataAPI } from '../../Helpers/FetchAPI';
import { forgotPassword, forgotPasswordSubmit } from '../../utils/cognitoAuth';
import Button from '../../components/Button';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [snackBarSuccess, setSnackBarSuccess] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarFail, setSnackBarFail] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    await getDataAPI(`/user/find-user/${resetPasswordEmail}`)
      .then((res) => {
        if (res.data.success) {
          forgotPassword(resetPasswordEmail)
            .then((resp) => {
              setShow(true);
              setSnackBarSuccess(true);
              setSnackBarMessage('Verification code sent. Please check your email.');
            })
            .catch((err) => {
              setSnackBarFail(true);
              setSnackBarMessage(err.message);
            });
        }
      })
      .catch((err) => {
        if (err) {
          setSnackBarFail(true);
          setSnackBarMessage("Email doesn't exist.");
        }
      });
  };

  function handleSubmitPassword(e) {
    e.preventDefault();

    forgotPasswordSubmit(resetPasswordEmail, code, password)
      .then((res) => {
        setSnackBarSuccess(true);
        setSnackBarMessage('Your password has been reset. Please log in again.');
        navigate('/');
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarSuccess(false);
    setSnackBarFail(false);
    setSnackBarMessage('');
  };

  return (
    <Container component="main" maxWidth="sm">
      <Typography
        color="primary.neutral"
        component="h1"
        variant="h1"
        sx={{
          mb: 1,
          fontSize: '3rem',
          fontWeight: 500
        }}
      >
        Forgot Your Password?
      </Typography>
      <Typography
        component="p"
        variant="p"
        sx={{
          mb: 6
        }}
      >
        No problem! Reset your password below.
      </Typography>
      {!show && (
        <form
          onSubmit={handleResetPassword}
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <div className="label_textfield">
            <span>Email*</span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.target.value)}
              inputProps={{ className: 'textField' }}
            />
          </div>
          <Button type="submit">Send a Code</Button>
        </form>
      )}
      {show && (
        <form
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'flex-start',
            flexDirection: 'column'
          }}
          onSubmit={handleSubmitPassword}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            value={resetPasswordEmail}
            onChange={(e) => setResetPasswordEmail(e.target.value)}
            inputProps={{ className: 'textField' }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            type="text"
            id="code"
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            inputProps={{ className: 'textField' }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{ className: 'textField' }}
          />

          <Button type="submit">Set Password</Button>
          <br />
          <span>
            <i>
              Didn't get a code? Resend it <a href="/forgot-password">here</a>
            </i>
          </span>
        </form>
      )}
      <Snackbar
        open={snackBarSuccess}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackBarFail}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPassword;
